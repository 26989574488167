import { Mission } from '../interfaces/missions.interface'
import { Button, Card, Typography } from '@mui/material'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { DateFormater } from '../../../shared/functions/date'
import { useNavigate } from 'react-router-dom'
import { ReactNode } from 'react'
import GetMissionLabel from '../../../shared/functions/mission-label'
import classNames from 'classnames'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { NotificationStatus } from '../../Notification/interfaces/notification.interface'

interface MissionCardProps {
    mission: Mission
    title?: ReactNode
    noNavigation?: boolean
    onDelete?: () => void
}

const MissionCard: React.FC<MissionCardProps> = ({ mission, title, noNavigation, onDelete }) => {
    const navigate = useNavigate()
    const missionTypeDetails = GetMissionLabel(mission.typeMission)
    
    const getReferenceByIdString = (id: string) => {
        if (!id) {
            return ''
        }
        return id.substring(id.length - 5, id.length).toUpperCase()
    }
    return (
        <Card>
            <Typography className="px-8 pt-3" fontWeight="bold" variant="h5" component="div">
                {title ?? ''}{` N° HWK${getReferenceByIdString(mission._id ?? '')}`}
            </Typography>
            <div className="px-8 col-span-4">
                {missionTypeDetails && (
                    <div className="grid grid-cols-10 gap-4 py-2">
                        <div className="flex justify-center items-center">
                            <GpsNotFixedIcon />
                        </div>
                        <div className="col-span-9 flex flex-col">
                            <Typography>Mission {mission?.domaine?.name} * {missionTypeDetails.label}</Typography>
                            <Typography
                                className={classNames('text-slate-400', {
                                    'max-w-xs truncate': noNavigation !== true
                                })}
                            >
                                {missionTypeDetails.description}
                            </Typography>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-10 gap-4">
                    <div className="flex justify-center items-center">
                        <CalendarTodayIcon />
                    </div>
                    <div className="col-span-9 flex flex-col">
                        <Typography>{DateFormater(String(mission.jours[0].date), true)}</Typography>
                        <Typography className="text-slate-400">Jour 1 sur {mission.jours.length}</Typography>
                    </div>
                </div>
                <div className="grid grid-cols-10 gap-4 py-2">
                    <div className="flex justify-center items-center">
                        <QueryBuilderIcon />
                    </div>
                    <div className="col-span-9 flex flex-col">
                        <Typography>
                            {mission.jours[0].startHour} - {mission.jours[0].endHour}
                        </Typography>
                        {/* <Typography className="text-slate-400">4h</Typography> */}
                    </div>
                </div>
                <div className="grid grid-cols-10 gap-4 py-2">
                    <div className="flex justify-center items-center">
                        <RoomOutlinedIcon />
                    </div>
                    <div
                        className={classNames('col-span-9 flex flex-col', {
                            'max-w-xs truncate': noNavigation !== true
                        })}
                    >
                        <Typography>{mission.localisation.address}</Typography>
                    </div>
                </div>
                {/* <div className="grid grid-cols-10 gap-4 py-2">
                    {mission &&
                        mission?.proposition?.length > 0 &&
                        mission?.proposition?.map((prestataire:any, i:number) => (
                            <>
                                    <div className="flex justify-center items-center">
                                        <PersonOutlineRoundedIcon /> 
                                    </div>
                                    <div className={classNames('col-span-9 flex-col')}>
                            {prestataire.prestataire.firstname + '  '} 
                                    {prestataire.state === NotificationStatus.ACCEPTED ? (
                                        <Button variant="contained" color="success" size="small">
                                            Accepté
                                        </Button>
                                    ) : prestataire.state === NotificationStatus.REFUSED ? (
                                        <Button variant="contained" color="error" size="small">
                                            Non-accepté
                                        </Button>
                                    ) : prestataire.state === NotificationStatus.PENDING ? (
                                        <Button variant="contained" color="primary" size="small">
                                            En attente
                                        </Button>
                                    ) : prestataire.state === NotificationStatus.COMMENCE ? (
                                        <Button variant="contained" color="info" size="small">
                                            Débuté
                                        </Button>
                                    ) : prestataire.state === NotificationStatus.TERMINE ? (
                                        <Button variant="contained" color="warning" size="small">
                                            Terminé
                                        </Button>
                                    ) : prestataire.state === NotificationStatus.PAYE ? (
                                        <Button variant="contained" color="success" size="small">
                                            Payé
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                    </div>
                                {i === mission?.proposition.length - 1 && <hr />}
                            </>
                    ))}
                </div> */}

            </div>
            {noNavigation !== true && (
                <div className="grid grid-cols-2 w-full gap-4 py-8 px-2">
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '20px', minWidth: '100px', backgroundColor: BASE_COLOR.PRIMARY }}
                        onClick={() => navigate(`${mission._id}`)}
                        className="w-full"
                    >
                        Détails
                    </Button>
                    <Button
                        sx={{
                            borderRadius: '20px',
                            minWidth: '100px'
                        }}
                        variant="contained"
                        color="error"
                        className="w-full"
                        onClick={onDelete}
                    >
                        Supprimer
                    </Button>
                </div>
            )}
        </Card>
    )
}

export default MissionCard
